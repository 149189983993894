import React from 'react';

const WarunkiKorzystania = () => {
    return (
        <div className="px-4 py-8 mx-auto max-w-screen-lg">
            <h1 className="text-3xl font-bold mb-6">Warunki Korzystania ze strony HakunaHouse.pl</h1>
            <div className="text-gray-800 leading-relaxed">
                <h2 className="text-xl font-bold mb-3">1. Postanowienia ogólne</h2>
                <p className="mb-3">
                    1.1. Niniejsze Warunki Korzystania określają zasady korzystania z strony internetowej HakunaHouse.pl ("Strona"), prowadzonej przez HakunaHouse Sp. z o.o. ("Administrator").
                </p>
                <p className="mb-3">
                    1.2. Korzystając ze Strony, Użytkownik akceptuje niniejsze Warunki Korzystania oraz zobowiązuje się do przestrzegania obowiązujących przepisów prawa oraz zasad netykiety.
                </p>

                <h2 className="text-xl font-bold mb-3">2. Zakres świadczeń</h2>
                <p className="mb-3">
                    2.1. Strona służy informacyjnym i marketingowym celom Administratora. Zawarte na Stronie treści mają charakter wyłącznie informacyjny i nie stanowią oferty handlowej w rozumieniu przepisów prawa.
                </p>
                <p className="mb-3">
                    2.2. Administrator zastrzega sobie prawo do zmiany zawartości Strony oraz oferowanych na niej produktów i usług w dowolnym czasie, bez wcześniejszego powiadomienia Użytkowników.
                </p>

                <h2 className="text-xl font-bold mb-3">3. Prawa autorskie</h2>
                <p className="mb-3">
                    3.1. Wszelkie prawa do treści umieszczonych na Stronie, w tym tekstów, grafik, zdjęć, logo, layoutu Strony, są zastrzeżone na rzecz Administratora lub innych podmiotów, z którymi Administrator zawarł odpowiednie umowy.
                </p>
                <p className="mb-3">
                    3.2. Użytkownik nie ma prawa do kopiowania, modyfikowania, rozpowszechniania ani wykorzystywania w jakikolwiek sposób treści Strony bez uprzedniej zgody pisemnej Administratora.
                </p>

                <h2 className="text-xl font-bold mb-3">4. Dane osobowe</h2>
                <p className="mb-3">
                    4.1. Przetwarzanie danych osobowych Użytkowników odbywa się zgodnie z Polityką Prywatności dostępną na Stronie.
                </p>

                <h2 className="text-xl font-bold mb-3">5. Odpowiedzialność</h2>
                <p className="mb-3">
                    5.1. Administrator dokłada wszelkich starań, aby treści umieszczone na Stronie były rzetelne i aktualne. Nie ponosi jednak odpowiedzialności za szkody wynikłe z korzystania lub niemożności korzystania ze Strony, chyba że szkoda ta jest wynikiem umyślnego działania Administratora.
                </p>
                <p className="mb-3">
                    5.2. Administrator nie ponosi odpowiedzialności za działania Użytkowników naruszające obowiązujące przepisy prawa lub zasady korzystania z Internetu.
                </p>

                <h2 className="text-xl font-bold mb-3">6. Zmiany Warunków Korzystania</h2>
                <p className="mb-3">
                    6.1. Administrator zastrzega sobie prawo do zmiany niniejszych Warunków Korzystania w dowolnym czasie. Zmiany wchodzą w życie z chwilą ich opublikowania na Stronie.
                </p>

                <h2 className="text-xl font-bold mb-3">7. Postanowienia końcowe</h2>
                <p className="mb-3">
                    7.1. Wszelkie spory wynikłe z korzystania ze Strony rozstrzygane będą przez właściwe sądy powszechne według prawa właściwego dla siedziby Administratora.
                </p>
                <p className="mb-3">
                    7.2. W przypadku wprowadzenia przez sąd właściwy postanowień uznających któreś z postanowień niniejszych Warunków Korzystania za nieważne lub niewykonalne, nie wpływa to na ważność pozostałych postanowień.
                </p>
            </div>
        </div>
    );
};

export default WarunkiKorzystania;
