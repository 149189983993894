import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Listings from './components/Listings';
import ListingDetails from './components/ListingDetails';
import CookieConsent from './components/Cookie';
import PolitykaPrywatnosci from './pages/PolitykaPrywatnosci';
import WarunkiKorzystania from './pages/WarunkiKorzystania';
import PolitykaCookies from './pages/PolitykaCookies';
import Services from './components/Services';
import Team from './components/Team';
import Contact from './components/Contact';
import Hero from "./components/Hero";
import NotFound from './components/NotFound';
import 'aos/dist/aos.css';
import BannerInfo from "./components/BannerInfo";

const AppContent = () => {
    const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(false);
    const [isHomePage, setIsHomePage] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
        if (!savedPreferences) {
            setIsCookieConsentVisible(true);
        }
        setIsHomePage(location.pathname === "/");
    }, [location.pathname]);

    const isSpecialPage = location.pathname === "/polityka-prywatnosci" || location.pathname === "/warunki-korzystania" || location.pathname === "/polityka-cookies";

    return (
        <div>
            <BannerInfo />
            <Header />
            {isHomePage && <Hero />}
            {isHomePage && <Services />}
            {isCookieConsentVisible && <CookieConsent setIsCookieConsentVisible={setIsCookieConsentVisible} />}
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Listings />} />
                <Route path="/listing/:id" element={<ListingDetails />} />
                <Route path="/polityka-prywatnosci" element={<PolitykaPrywatnosci />} />
                <Route path="/warunki-korzystania" element={<WarunkiKorzystania />} />
                <Route path="/polityka-cookies" element={<PolitykaCookies />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            {isHomePage && !isSpecialPage && (
                <>
                    <Team />
                    <Contact />
                </>
            )}
            <Footer />
        </div>
    );
};

const App = () => (
    <Router>
        <AppContent />
    </Router>
);

export default App;
