import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import zdjecia1 from '../assets/zdjecia1.webp';
import zdjecia2 from '../assets/zdjecia2.webp';
import zdjecia3 from '../assets/zdjecia3.webp';

const Hero = () => {
    return (
        <div className="relative">
            <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={5000} transitionTime={1000}>
                <div className="lg:h-[424px] sm:h-32">
                    <img src={zdjecia1} alt="Slajd 1" className="h-full w-full object-cover" loading="lazy" />
                </div>
                <div className="lg:h-[424px] sm:h-32">
                    <img src={zdjecia2} alt="Slajd 2" className="h-full w-full object-cover" loading="lazy" />
                </div>
                <div className="lg:h-[424px] sm:h-32">
                    <img src={zdjecia3} alt="Slajd 3" className="h-full w-full object-cover" loading="lazy" />
                </div>
            </Carousel>
        </div>
    );
};

export default Hero;
