import React from 'react';

const PolitykaPrywatnosci = () => {
    return (
        <div className="px-4 py-8 mx-auto max-w-screen-lg">
            <h1 className="text-3xl font-bold mb-4">Polityka Prywatności</h1>
            <div className="text-gray-800 leading-relaxed">
                <h2 className="text-xl font-bold mb-3">Ochrona Danych Osobowych</h2>
                <p className="mb-3">
                    W związku z RODO, HakunaHouse sp. z o.o. stosuje Zintegrowaną Politykę Ochrony Danych Osobowych,
                    która obowiązuje wszystkich pracowników i współpracowników oraz klientów.
                </p>
                <h2 className="text-xl font-bold mb-3">Klauzula Informacyjna RODO</h2>
                <p className="mb-3">
                    Zgodnie z art. 13 ust. 1 i 2 rozporządzenia RODO z dnia 27 kwietnia 2016 r. informujemy, że:
                </p>
                <p className="mb-3">
                    Administratorem Pani/Pana danych osobowych jest HakunaHouse sp. z o.o., Warszawa (dalej: „ADO”). ADO
                    można skontaktować się telefonicznie pod numerem: 604 093 352 lub mailowo pod adresem:
                    biuro@hakunahouse.pl.
                </p>
                <p className="mb-3">
                    Pani/Pana dane osobowe są przetwarzane przez ADO w celu przygotowania ofert i świadczenia usług
                    pośrednictwa w obrocie nieruchomościami na podstawie art. 6 ust. 1 lit. b) RODO, co oznacza, że
                    przetwarzanie danych jest konieczne do realizacji umowy lub podjęcia działań przed jej zawarciem.
                </p>
                <p className="mb-3">
                    Pani/Pana dane osobowe będą przetwarzane przez ADO przez okres 3 lat od końca roku kalendarzowego, w
                    którym złożono ofertę. Jeśli umowa zostanie zrealizowana, dokumentacja będzie przechowywana zgodnie
                    z wymogami prawnymi.
                </p>
                <p className="mb-3">
                    Pani/Pana dane nie będą przekazywane do krajów spoza Europejskiego Obszaru Gospodarczego.
                </p>
                <p className="mb-3">
                    Odbiorcami danych osobowych mogą być podmioty współpracujące z ADO, dostawcy usług, prawnicy oraz
                    firmy windykacyjne, zgodnie z obowiązującymi przepisami prawa.
                </p>
                <p className="mb-3">
                    Przysługuje Pani/Panu prawo dostępu do danych, ich sprostowania, usunięcia, ograniczenia
                    przetwarzania, przenoszenia danych, a także wniesienia sprzeciwu wobec przetwarzania danych.
                </p>
                <p className="mb-3">
                    W przypadku naruszenia przepisów RODO przysługuje Pani/Panu prawo do wniesienia skargi do Prezesa
                    Urzędu Ochrony Danych Osobowych.
                </p>
                <p className="mb-3">
                    Podanie danych osobowych jest dobrowolne, ale ich niepodanie uniemożliwi przygotowanie oferty.
                </p>

                <h2 className="text-xl font-bold mb-3">Pliki cookies</h2>
                <ul className="list-disc list-inside mb-3">
                    <p className="mb-3">
                        Serwis HakunaHouse sp. z o.o. nie gromadzi automatycznie żadnych informacji, z wyjątkiem tych
                        zawartych w plikach cookies.
                    </p>
                    <p className="mb-3">
                        Pliki cookies (tzw. „ciasteczka”) to dane informatyczne, głównie pliki tekstowe, które są
                        przechowywane na urządzeniu końcowym Użytkownika Serwisu. Cookies pozwalają korzystać z funkcji
                        Serwisu i zazwyczaj zawierają nazwę strony internetowej, czas przechowywania oraz unikalny
                        numer.
                    </p>
                    <li>Dostosowania treści stron Serwisu do preferencji Użytkownika oraz optymalizacji ich działania.
                    </li>
                    <li>Tworzenia statystyk, które pomagają zrozumieć, jak Użytkownicy korzystają z Serwisu, co
                        umożliwia jego ulepszanie.
                    </li>
                    <li>Utrzymania sesji Użytkownika Serwisu, dzięki czemu po zalogowaniu nie trzeba wpisywać ponownie
                        loginu i hasła na każdej podstronie.
                    </li>
                </ul>

                <ul className="list-disc list-inside mb-3">
                    <h4 className="text-xl mb-3">Rodzaje plików cookies</h4>
                    <li>Niezbędne – umożliwiające korzystanie z usług Serwisu.</li>
                    <li>Analityczne – zbierające dane na temat sposobu korzystania z Serwisu, aby poprawić jego
                        działanie.
                    </li>
                    <li>Marketingowe – wykorzystywane do personalizowania treści reklamowych wyświetlanych
                        Użytkownikowi.
                    </li>
                </ul>

                <p className="mb-3">
                    Domyślnie przeglądarki internetowe pozwalają na przechowywanie cookies. Można je jednak zmienić w
                    ustawieniach przeglądarki, aby blokować automatyczne zapisywanie cookies lub otrzymywać
                    powiadomienia o ich użyciu. Ograniczenie używania cookies może wpłynąć na niektóre funkcje Serwisu.
                </p>

            </div>
        </div>
    );
};

export default PolitykaPrywatnosci;
