import React, { useEffect, useState } from 'react';
import { FaSearch, FaPhone, FaEnvelope, FaUser, FaCommentAlt } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [contactInfo, setContactInfo] = useState({
        title: '',
        subtitle: '',
        description: '',
        form_title: '',
        form_subtitle: '',
        phone_1: '',
        phone_2: '',
        email: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        fetch('https://www.wivfy.space/php/src/api/zarzadzanie.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ action: 'get_management' })
        })
            .then(response => response.json())
            .then(data => setContactInfo(data.management.CONTACT || {}))
            .catch(error => console.error('Error fetching contact data:', error));

        AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1000ms
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        if (!formData.name || !formData.email || !formData.message) {
            return 'Wszystkie pola są wymagane.';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            return 'Wprowadź poprawny adres e-mail.';
        }
        return '';
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errorMessage = validateForm();
        if (errorMessage) {
            setError(errorMessage);
            return;
        }

        setIsSubmitting(true);
        setError('');
        setSuccess('');

        const templateParams = {
            from_name: formData.name,
            from_email: formData.email,
            message: formData.message,
        };

        emailjs.send(
            'service_mvc1i8j',
            'template_sv4cs94',
            templateParams,
            'WkbubIL6__yDJts-Z'
        )
            .then((result) => {
                setSuccess('Wiadomość została wysłana pomyślnie!');
                setIsSubmitting(false);
                setFormData({ name: '', email: '', message: '' });
            })
            .catch((error) => {
                console.error('Wystąpił błąd podczas wysyłania wiadomości:', error);
                setError('Wystąpił błąd podczas wysyłania wiadomości.');
                setIsSubmitting(false);
            });
    };

    return (
        <div>
            <div className="relative mx-auto max-w-5xl text-center py-20" data-aos="fade-up">
                <span id="contact" className="text-black my-3 flex items-center justify-center font-medium uppercase tracking-wider">
                    {contactInfo.title}
                </span>
                <h2 className="block w-full bg-clip-text text-[#a07c5c] text-2xl sm:text-2xl font-bold">
                    {contactInfo.subtitle}
                </h2>
                <hr className="border-t-2 border-[#a07c5c] my-4 mx-auto"/>
                <p className="mx-auto  my-4 w-full max-w-4xl text-center font-medium leading-relaxed tracking-wide text-gray-600">
                    {contactInfo.description}
                </p>
            </div>
            <section className="container mx-auto rounded-lg grid grid-cols-1 md:grid-cols-2 gap-24">
                <div className="space-y-6 mt-10" data-aos="fade-up">
                    <ContactInfo
                        title="DANE KONTAKTOWE"
                        info={[
                            {icon: FaPhone, value: contactInfo.phone_1},
                            {icon: FaPhone, value: contactInfo.phone_2},
                            {icon: FaEnvelope, value: contactInfo.email},
                        ]}
                    />
                    <div className="flex items-center space-x-3">
                        <a href="https://otodom.pl"
                           className="text-gray-500 transition-colors duration-300 hover:text-[#a07c5c]">
                            <svg viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 text-[#a07c5c] hover:text-[#795b40]" aria-label="Otodom">
                                <rect width="24" height="24"/>
                                <circle cx="12" cy="12" r="6" fill="white"/>
                            </svg>
                        </a>
                        <a href="https://instagram.com"
                           className="text-gray-500 transition-colors duration-300 hover:text-[#a07c5c]">
                            <svg viewBox="0 0 28 28" fill="currentColor"
                                 className="h-6 w-6 text-[#a07c5c] hover:text-[#795b40]">
                                <circle cx="15" cy="15" r="4"/>
                                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10
                                        C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9
                                        c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"/>
                            </svg>
                        </a>
                        <a href="https://facebook.com"
                           className="text-gray-500 transition-colors duration-300 hover:text-[#a07c5c]">
                            <svg viewBox="0 -1 24 24" fill="currentColor"
                                 className="h-5 w-5 text-[#a07c5c] hover:text-[#795b40]">
                                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788
                                        c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22
                                        c1.105,0,2-0.895,2-2V2C24,0.895,23.105,0,22,0z"/>
                            </svg>
                        </a>
                    </div>
                    <div id="google-map" className="w-full">
                        <iframe
                            title="Lokalizacja firmy"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d161113.19078528656!2d20.87044114735157!3d52.23306535632315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecc53d8785a97%3A0x72a6860e867d4f8d!2sWarszawa!5e0!3m2!1spl!2spl!4v1622621852732!5m2!1spl!2spl"
                            className="w-full h-53 sm:h-80"
                            style={{border: '0'}}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
                <div className="mt-10" data-aos="fade-up">
                    <h2 className="text-2xl font-semibold mb-4 text-[#a07c5c]">{contactInfo.form_title}</h2>
                    <p className="text-sm text-gray-600 mb-6">{contactInfo.form_subtitle}</p>
                    <form onSubmit={handleSubmit}>
                        <FormField
                            id="name"
                            label="Imię i Nazwisko"
                            icon={FaUser}
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <FormField
                            id="email"
                            label="Adres Email"
                            icon={FaEnvelope}
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <FormField
                            id="message"
                            label="Treść Wiadomości"
                            icon={FaCommentAlt}
                            type="textarea"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                        <div>
                            <button type="submit"
                                    className="bg-[#a07c5c] text-white py-2 px-4 rounded-md flex items-center justify-center hover:bg-[#795b40]">
                                <FaSearch className="mr-2"/> Wyślij
                            </button>
                        </div>
                        {error && <p className="text-red-500 mt-4">{error}</p>}
                        {success && <p className="text-green-500 mt-4">{success}</p>}
                        {isSubmitting && <p>Wysyłanie...</p>}
                    </form>
                </div>
            </section>
        </div>
    );
};

const ContactInfo = ({title, info}) => (
    <div>
        <h4 className="text-lg font-semibold text-[#a07c5c]">{title}</h4>
        <hr className="my-3 border-[#a07c5c]"/>
        {info.map((item, index) => (
            <div key={index} className="flex items-center mt-2">
                {React.createElement(item.icon, { className: 'text-[#a07c5c] mr-3' })}
                <div>
                    <p className="text-sm"><b>{item.value}</b></p>
                </div>
            </div>
        ))}
    </div>
);

const FormField = ({ id, label, icon, type, value, onChange, required }) => (
    <div className="mb-4 space-y-2">
        <div className="flex items-center">
            {React.createElement(icon, { className: 'text-[#a07c5c] mr-2' })}
            <label htmlFor={id} className="block text-sm font-medium text-gray-600">{label}</label>
        </div>
        {type === 'textarea' ? (
            <textarea
                id={id}
                name={id}
                rows="5"
                required={required}
                value={value}
                onChange={onChange}
                className="p-2 w-full border rounded-md focus:outline-none focus:border-[#a07c5c] border-[#a07c5c]"
                style={{ height: '220px' }}
            />
        ) : (
            <input
                type={type}
                id={id}
                name={id}
                required={required}
                value={value}
                onChange={onChange}
                className="p-2 w-full border rounded-md focus:outline-none focus:border-[#a07c5c] border-[#a07c5c]"
            />
        )}
    </div>
);

export default Contact;
