import React from "react";

const BannerInfo = () => {
    return (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 text-center ">
            <p>Strona jest w fazie testów. Mogą występować błędy lub niedostępność niektórych funkcji.</p>
        </div>
    );
};

export default BannerInfo;
