import React, { useEffect, useState, useRef } from 'react';
import { PhoneIcon, EnvelopeIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import logo from '../assets/logo.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Header = () => {
    const [headerData, setHeaderData] = useState({});
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const headerRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const fetchHeaderData = async () => {
            try {
                const response = await fetch('https://www.wivfy.space/php/src/api/zarzadzanie.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ action: 'get_management' })
                });
                const data = await response.json();
                setHeaderData(data.management.HEADER || {});
            } catch (error) {
                console.error('Error fetching header data:', error);
            }
        };

        fetchHeaderData();
        AOS.init({ duration: 1000 });

        const handleScroll = () => {
            if (window.scrollY > headerRef.current.offsetTop) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(prevState => !prevState);
    };

    const scrollToWithOffset = (id, offset) => {
        const element = document.getElementById(id);
        const yOffset = offset || -100; // Dostosuj offset do wysokości nagłówka
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const SOCIAL_LINKS = React.useMemo(() => ([
        {
            href: "https://www.otodom.pl",
            icon: (
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 text-[#a07c5c] hover:text-[#795b40]" aria-label="Otodom">
                    <rect width="24" height="24" />
                    <circle cx="12" cy="12" r="6" fill="white" />
                </svg>
            ),
        },
        {
            href: "https://instagram.com",
            icon: (
                <svg viewBox="0 0 28 28 " fill="currentColor" className="h-6 w-6 text-[#a07c5c] hover:text-[#795b40]" aria-label="Instagram">
                    <circle cx="15" cy="15" r="4" />
                    <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
            ),
        },
        {
            href: "https://facebook.com",
            icon: (
                <svg viewBox="0 -1 24 24" fill="currentColor" className="h-5 w-5 text-[#a07c5c] hover:text-[#795b40]" aria-label="Twitter">
                    <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2V2C24,0.895,23.105,0,22,0z" />
                </svg>
            ),
        },
    ]), []);

    return (
        <div className="relative">
            <div className="text-[#a07c5c] bg-gray-100 py-2" data-aos="fade-down">
                <div
                    className="container mx-auto px-4 flex flex-col md:flex-row justify-start items-center space-y-2 md:space-y-0 md:space-x-4">
                    <div className="flex items-center space-x-1">
                        <PhoneIcon className="h-5 w-5"/>
                        <span>{headerData.phone}</span>
                    </div>
                    <div className="flex items-center space-x-1">
                        <EnvelopeIcon className="h-5 w-5"/>
                        <span>{headerData.email}</span>
                    </div>
                </div>
            </div>

            <header ref={headerRef}
                    className={`bg-white shadow-md ${isSticky ? 'fixed top-0 w-full z-50' : 'relative'}`}>
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <div className="flex items-center space-x-4" data-aos="zoom-in">
                        <a href="/">
                            <img src={logo} alt="Logo" className="h-10 w-auto"/>
                        </a>
                        <a href="/" className="text-xl font-medium bg-clip-text text-[#a07c5c] ">
                            {headerData.title}
                        </a>
                    </div>
                    <nav className="hidden md:flex items-center md:-ml-20 space-x-6" data-aos="zoom-in">
                        <button onClick={() => scrollToWithOffset('services', -150)}
                                className="text-[#a07c5c] font-medium hover:text-[#795b40]">O Nas
                        </button>
                        {/* Offset -120 */}
                        <button onClick={() => scrollToWithOffset('offers', -40)}
                                className="text-[#a07c5c] font-medium hover:text-[#795b40]">Oferty
                        </button>
                        {/* Offset -100 */}
                        <button onClick={() => scrollToWithOffset('team', -210)}
                                className="text-[#a07c5c] font-medium hover:text-[#795b40]">Zespół
                        </button>
                        {/* Offset -90 */}
                        <button onClick={() => scrollToWithOffset('contact', -250)}
                                className="text-[#a07c5c] font-medium hover:text-[#795b40]">Kontakt
                        </button>
                        {/* Offset -100 */}
                    </nav>

                    <div className="hidden md:flex items-center space-x-4">
                        {SOCIAL_LINKS.map((link, index) => (
                            <a key={index} href={link.href} target="_blank" rel="noopener noreferrer"
                               className="text-[#a07c5c] hover:text-[#795b40]" data-aos="zoom-in">
                                {link.icon}
                            </a>
                        ))}
                    </div>
                    <div className="md:hidden">
                        <button onClick={toggleMobileMenu} className="text-[#a07c5c]">
                            {isMobileMenuOpen ? <XMarkIcon className="h-6 w-6"/> : <Bars3Icon className="h-6 w-6"/>}
                        </button>
                    </div>
                </div>
            </header>

            <div
                className={`md:hidden bg-white text-[#a07c5c] transition-all duration-300 ease-in-out ${isMobileMenuOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
                {isMobileMenuOpen && (
                    <nav className="flex flex-col items-center space-y-2 py-4">
                        <button onClick={() => scrollToWithOffset('services', -160)}
                                className="text-[#a07c5c] font-medium hover:text-[#795b40]">O Nas
                        </button>
                        {/* Offset -120 */}
                        <button onClick={() => scrollToWithOffset('offers', -100)}
                                className="text-[#a07c5c] font-medium hover:text-[#795b40]">Oferty
                        </button>
                        {/* Offset -100 */}
                        <button onClick={() => scrollToWithOffset('team', -180)}
                                className="text-[#a07c5c] font-medium hover:text-[#795b40]">Zespół
                        </button>
                        {/* Offset -90 */}
                        <button onClick={() => scrollToWithOffset('contact', -100)}
                                className="text-[#a07c5c] font-medium hover:text-[#795b40]">Kontakt
                        </button>
                        {/* Offset -100 */}
                    </nav>
                )}
            </div>

        </div>
    );
};

export default Header;
