import React, { useEffect, useState } from 'react';
import { PhoneIcon } from '@heroicons/react/24/solid';
import user from '../assets/user.webp';
import user2 from '../assets/user2.webp';
import {FaEnvelope} from "react-icons/fa";

const Team = () => {
    const [teamData, setTeamData] = useState({
        title: '',
        subtitle: '',
        members: [],
    });

    useEffect(() => {
        fetch('https://www.wivfy.space/php/src/api/zarzadzanie.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ action: 'get_management' })
        })
            .then(response => response.json())
            .then(data => {
                const teamInfo = data.management.TEAM || {};
                setTeamData({
                    title: teamInfo.title || '',
                    subtitle: teamInfo.subtitle || '',
                    members: [
                        {
                            name: teamInfo.member_1_name,
                            role: teamInfo.member_1_role,
                            phone: teamInfo.member_1_phone,
                            availability: teamInfo.member_1_availability,
                            image: user,
                        },
                        {
                            name: teamInfo.member_2_name,
                            role: teamInfo.member_2_role,
                            phone: teamInfo.member_2_phone,
                            availability: teamInfo.member_2_availability,
                            image: user2,
                        }
                    ]
                });
            })
            .catch(error => console.error('Error fetching team data:', error));
    }, []);

    return (
        <div data-aos="fade-up" className="relative overflow-hidden text-[#a07c5c] mt-4 -mb-10 to-white rounded-lg" style={{ backgroundColor: '#ffffff', backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'88\' height=\'24\' viewBox=\'0 0 88 24\'%3E%3Cg fill-rule=\'evenodd\'%3E%3Cg id=\'autumn\' fill=\'%23a07c5c\' fill-opacity=\'0.07\'%3E%3Cpath d=\'M10 0l30 15 2 1V2.18A10 10 0 0 0 41.76 0H39.7a8 8 0 0 1 .3 2.18v10.58L14.47 0H10zm31.76 24a10 10 0 0 0-5.29-6.76L4 1 2 0v13.82a10 10 0 0 0 5.53 8.94L10 24h4.47l-6.05-3.02A8 8 0 0 1 4 13.82V3.24l31.58 15.78A8 8 0 0 1 39.7 24h2.06zM78 24l2.47-1.24A10 10 0 0 0 86 13.82V0l-2 1-32.47 16.24A10 10 0 0 0 46.24 24h2.06a8 8 0 0 1 4.12-4.98L84 3.24v10.58a8 8 0 0 1-4.42 7.16L73.53 24H78zm0-24L48 15l-2 1V2.18A10 10 0 0 1 46.24 0h2.06a8 8 0 0 0-.3 2.18v10.58L73.53 0H78z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")' }}>
            <div id="team" className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
                <div className="relative mx-auto max-w-5xl text-center  mb-10" data-aos="fade-up">
                    <span className="text-black my-3 flex items-center justify-center font-medium uppercase tracking-wider">Z e s p ó ł</span>
                    <h2 className="block w-full bg-clip-text text-[#a07c5c] text-2xl sm:text-2xl font-bold">
                        {teamData.title}
                    </h2>
                    <hr className="border-t-2 border-[#a07c5c] my-4 mx-auto"/>
                    <p className="mx-auto my-4 w-full max-w-4xl text-center font-medium leading-relaxed tracking-wide text-gray-600">
                        {teamData.subtitle}
                    </p>
                </div>
                <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-4 lg:max-w-screen-lg mt-12 sm:flex sm:justify-center">
                    {teamData.members.map((member, index) => (
                        <div key={index} className="sm:w-64">
                            <div className="relative pb-56 mb-4 rounded shadow lg:pb-64">
                                <img className="absolute object-cover w-full h-full rounded" src={member.image} alt={member.name} />
                            </div>
                            <div className="flex flex-col sm:text-center">
                                <p className="mb-1 text-lg font-bold">{member.name}</p>
                                <p className="mb-1 text-sm text-gray-800">{member.role}</p>
                                <div className="flex items-center space-x-3 sm:justify-center">
                                    <FaEnvelope className="mb-2 text-[#a07c5c] w-5 h-5"/>
                                    <p className="mb-2 text-sm text-[#a07c5c]">{member.availability}</p>
                                </div>
                                <div className="flex items-center space-x-3 sm:justify-center">
                                    <PhoneIcon className="mb-2 text-[#a07c5c] w-5 h-5"/>
                                    <p className="mb-2 text-sm text-[#a07c5c]">{member.phone}</p>
                                </div>
                            </div>
                        </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Team;
