import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const NotFound = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-2xl mx-auto text-center">
                <h2 className="text-5xl font-extrabold text-gray-800 mb-4">404</h2>
                <h3 className="text-2xl font-semibold text-gray-700 mb-2">Strona nie znaleziona</h3>
                <p className="text-gray-600 mb-6">Strona, której szukasz, nie istnieje. Może została usunięta lub nigdy nie istniała.</p>
                <div className="flex justify-center mb-8">
                    <Link to="/" className="inline-flex items-center px-4 py-2 bg-[#a07c5c] text-white text-lg font-semibold rounded-md hover:bg-[#795b40]">
                        <FaHome className="mr-2" /> Powrót do strony głównej
                    </Link>
                </div>
                <div className="text-left">
                    <p className="text-gray-700 mb-4">Oops! Wygląda na to, że trafiłeś na stronę, która nie istnieje. Oto kilka rzeczy, które możesz spróbować:</p>
                    <ul className="list-disc list-inside text-gray-700 mb-6">
                        <li>Sprawdź URL pod kątem błędów lub literówek</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
