import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-50 mt-10">
            <div className="container mx-auto px-4 py-8 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8">
                <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
                </div>
                <div className="flex flex-col-reverse justify-between items-center pt-5 pb-10 border-t lg:flex-row">
                    <p className="text-sm text-gray-600 mt-4 lg:mt-0">
                        © {new Date().getFullYear()} HakunaHouse.pl. Wszelkie prawa zastrzeżone.
                    </p>

                    <div className="flex items-center space-x-4">
                        <ul className="flex space-x-4">
                            <li>
                                <a href="/polityka-prywatnosci"
                                   className="text-sm text-gray-600 transition-colors duration-300 hover:text-[#795b40]">
                                    Polityka Prywatności
                                </a>
                            </li>
                            <li>
                                <a href="/polityka-cookies"
                                   className="text-sm text-gray-600 transition-colors duration-300 hover:text-[#795b40]">
                                    Polityka Cookies
                                </a>
                            </li>
                            <li>
                                <button
                                    className="text-sm text-gray-600 transition-colors duration-300 hover:text-[#795b40]">
                                    Realizacja Siteify
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
