import React, { useState } from 'react';
import { FaCookieBite, FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DEFAULT_PREFERENCES = {
    necessary: true,
    analytics: false,
    marketing: false,
};

const LOCAL_STORAGE_KEY = 'cookiePreferences';

const Cookie = ({ setIsCookieConsentVisible }) => {
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

    const handleAcceptAll = () => {
        const newPreferences = {
            necessary: true,
            analytics: true,
            marketing: true,
        };
        setPreferences(newPreferences);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newPreferences));
        setIsCookieConsentVisible(false);
    };

    const handleSavePreferences = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(preferences));
        setIsCookieConsentVisible(false);
    };

    const handleChangePreference = (key) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            [key]: !prevPreferences[key],
        }));
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-96">

                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold flex items-center">
                        <FaCookieBite className="mr-2 text-yellow-500" />
                        Używamy plików cookie
                    </h2>
                </div>

                <p className="mb-4">Ta strona używa plików cookie do poprawy doświadczenia użytkownika. Wybierz swoje preferencje.</p>

                <div className="mb-4">
                    <div className="flex items-center mb-2">
                        <input type="checkbox" checked={preferences.necessary} readOnly className="mr-2" />
                        <label className="flex-grow">Niezbędne pliki cookie</label>
                    </div>
                    <div className="flex items-center mb-2">
                        <input type="checkbox" checked={preferences.analytics} onChange={() => handleChangePreference('analytics')} className="mr-2" />
                        <label className="flex-grow">Analityczne pliki cookie</label>
                    </div>
                    <div className="flex items-center mb-2">
                        <input type="checkbox" checked={preferences.marketing} onChange={() => handleChangePreference('marketing')} className="mr-2" />
                        <label className="flex-grow">Marketingowe pliki cookie</label>
                    </div>
                </div>

                <div className="flex justify-end">
                    <button onClick={handleAcceptAll} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2 flex items-center">
                        <FaCheck className="mr-2" />
                        Akceptuj wszystkie
                    </button>
                    <button onClick={handleSavePreferences} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center">
                        <FaCheck className="mr-2" />
                        Zapisz preferencje
                    </button>
                </div>

                <div className="mt-4 text-right">
                    <Link to="/polityka-cookies" className="text-blue-500 hover:underline">Polityka plików cookies</Link>
                </div>
            </div>
        </div>
    );
};

export default Cookie;
