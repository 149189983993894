import React from 'react';

const PolitykaCookies = () => {
    return (
        <div className="px-4 py-8 mx-auto max-w-screen-lg">
            <h1 className="text-3xl font-bold mb-6">Polityka plików cookies</h1>
            <div className="text-gray-800 leading-relaxed">
                <h2 className="text-xl font-bold mb-3">1. Informacje ogólne</h2>
                <p className="mb-3">
                    1.1. Strona internetowa HakunaHouse.pl ("Strona") używa plików cookies. Są to małe pliki tekstowe przechowywane na urządzeniu Użytkownika podczas odwiedzania Strony.
                </p>
                <p className="mb-3">
                    1.2. Niniejsza Polityka określa rodzaje plików cookies używanych na Stronie oraz sposób ich wykorzystania.
                </p>

                <h2 className="text-xl font-bold mb-3">2. Rodzaje plików cookies</h2>
                <p className="mb-3">
                    2.1. Pliki cookies niezbędne do działania Strony: Pliki te są niezbędne do zapewnienia prawidłowego funkcjonowania Strony, umożliwiają nawigację po Stronie oraz dostęp do zabezpieczonych obszarów Strony. Bez tych plików niektóre funkcje Strony mogą być niedostępne.
                </p>
                <p className="mb-3">
                    2.2. Pliki cookies analityczne: Pliki te pozwalają na analizę sposobu korzystania ze Strony przez Użytkowników, co umożliwia poprawę jakości i efektywności działania Strony.
                </p>
                <p className="mb-3">
                    2.3. Pliki cookies marketingowe: Pliki te są używane do śledzenia działań Użytkowników na Stronie w celu dostarczania spersonalizowanych treści marketingowych oraz reklamowych.
                </p>

                <h2 className="text-xl font-bold mb-3">3. Zgoda na używanie plików cookies</h2>
                <p className="mb-3">
                    3.1. Korzystając ze Strony, Użytkownik wyraża zgodę na używanie plików cookies zgodnie z niniejszą Polityką.
                </p>
                <p className="mb-3">
                    3.2. Użytkownik ma możliwość zarządzania ustawieniami dotyczącymi plików cookies poprzez odpowiednie opcje dostępne w ustawieniach przeglądarki internetowej.
                </p>

                <h2 className="text-xl font-bold mb-3">4. Wyłączenie plików cookies</h2>
                <p className="mb-3">
                    4.1. Użytkownik ma możliwość wyłączenia lub ograniczenia używania plików cookies poprzez odpowiednie ustawienia przeglądarki internetowej. Należy jednak pamiętać, że wyłączenie plików cookies może wpłynąć na funkcjonalność Strony oraz ograniczyć dostęp do niektórych jej części.
                </p>

                <h2 className="text-xl font-bold mb-3">5. Przechowywanie danych</h2>
                <p className="mb-3">
                    5.1. Informacje uzyskane za pomocą plików cookies są przechowywane przez okres niezbędny do realizacji ich celu oraz zgodnie z wymaganiami prawa.
                </p>

                <h2 className="text-xl font-bold mb-3">6. Zmiany w Polityce cookies</h2>
                <p className="mb-3">
                    6.1. Administrator zastrzega sobie prawo do wprowadzania zmian w niniejszej Polityce cookies w dowolnym czasie. Zmiany wchodzą w życie z chwilą ich opublikowania na Stronie.
                </p>

                <h2 className="text-xl font-bold mb-3">7. Kontakt</h2>
                <p className="mb-3">
                    7.1. Wszelkie pytania dotyczące Polityki cookies można kierować na adres e-mail: biuro@hakunahouse.pl.
                </p>
            </div>
        </div>
    );
};

export default PolitykaCookies;
