import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaMapMarkerAlt, FaMoneyBillWave, FaSearch, FaHome, FaTimes, FaExpandArrowsAlt } from 'react-icons/fa';
import Card from './Card';

const Listings = () => {
    const [searchData, setSearchData] = useState({
        miejscowosc_dzielnica: '',
        typ_nieruchomosci: '',
        transakcja: '',
        cena_od: '',
        cena_do: '',
        powierzchnia_od: '',
        powierzchnia_do: '',
    });
    const [filteredResults, setFilteredResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get('/miasta.txt'); // Fetch from public directory
                const cityList = response.data.split('\n').map(city => city.trim()).filter(city => city);
                setCities(cityList);
            } catch (err) {
                setError('Wystąpił błąd podczas pobierania miast!');
            }
        };
        fetchCities();
    }, []);

    const fetchListings = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post('https://www.wivfy.space/php/src/api/ogloszenia.php', searchData);
            setFilteredResults(response.data);
        } catch (err) {
            setError('Wystąpił błąd podczas pobierania ogłoszeń!');
        } finally {
            setLoading(false);
        }
    }, [searchData]);

    useEffect(() => {
        fetchListings();
    }, [fetchListings]);

    useEffect(() => {
        if (showSuggestions && searchData.miejscowosc_dzielnica.length >= 2) { // Sprawdzenie, czy wpisano co najmniej 2 znaki
            setCitySuggestions(cities.filter(city =>
                city.toLowerCase().startsWith(searchData.miejscowosc_dzielnica.toLowerCase())
            ));
        } else {
            setCitySuggestions([]); // Jeśli wpisano mniej niż 2 znaki, nie pokazuj sugestii
        }
    }, [searchData.miejscowosc_dzielnica, showSuggestions, cities]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleClear = (field) => {
        setSearchData(prevData => ({ ...prevData, [field]: '' }));
        if (field === 'miejscowosc_dzielnica') {
            setCitySuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchListings();
    };

    const prevPage = () => {
        if (currentPage > 1) setCurrentPage(prev => prev - 1);
    };

    const nextPage = () => {
        if (currentPage < Math.ceil(filteredResults.length / itemsPerPage)) setCurrentPage(prev => prev + 1);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentListings = filteredResults.slice(indexOfFirstItem, indexOfLastItem);

    const handleCitySelect = (city) => {
        setSearchData(prevData => ({ ...prevData, miejscowosc_dzielnica: city }));
        setCitySuggestions([]);
        setShowSuggestions(false);
    };

    const toggleSuggestions = () => {
        setShowSuggestions(true);
    };

    return (
        <div id="offers" className="container mx-auto p-4">
            <div className="relative bg-white mx-auto max-w-5xl text-center mt-10" data-aos="fade-up">
                <span className="text-black my-3 flex items-center justify-center font-medium uppercase tracking-wider">
                    O f e r t y
                </span>
                <h2 className="block w-full bg-clip-text text-[#a07c5c] text-2xl sm:text-2xl font-bold">
                    Nasze oferty
                </h2>
                <hr className="border-t-2 border-[#a07c5c] my-4 mx-auto"/>
                <p className="mx-auto mb-10 my-4 w-full max-w-4xl text-center font-medium leading-relaxed tracking-wide text-gray-600">
                    Zapraszamy do zapoznania się z ofertami naszego biura nieruchomości. Nasz zespół chętnie pomoże
                    znaleźć idealne rozwiązanie dopasowane do Państwa potrzeb.
                </p>
            </div>

            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
                    {[
                        {
                            name: 'miejscowosc_dzielnica',
                            placeholder: 'Lokalizacja',
                            type: 'text',
                            icon: FaMapMarkerAlt
                        },
                        {
                            name: 'powierzchnia_od',
                            type: 'number',
                            placeholder: 'Powierzchnia od',
                            icon: FaExpandArrowsAlt
                        },
                        {
                            name: 'cena_od',
                            type: 'number',
                            placeholder: 'Cena od',
                            icon: FaMoneyBillWave
                        },
                        {
                            name: 'transakcja',
                            type: 'select',
                            options: ['Typ transakcji', 'Sprzedaż', 'Wynajem'],
                            icon: FaHome
                        },
                        {
                            name: 'typ_nieruchomosci',
                            type: 'select',
                            options: ['Rodzaj nieruchomości', 'Mieszkanie', 'Dom', 'Dzialka', 'Lokal użytkowy'],
                            icon: FaHome
                        },
                        {
                            name: 'powierzchnia_do',
                            type: 'number',
                            placeholder: 'Powierzchnia do',
                            icon: FaExpandArrowsAlt
                        },
                        {
                            name: 'cena_do',
                            type: 'number',
                            placeholder: 'Cena do',
                            icon: FaMoneyBillWave
                        },
                    ].map((field, index) => (
                        <div key={index} className="relative">
                            {field.icon && (
                                <field.icon
                                    className={`absolute left-3 top-3 text-gray-400 ${field.name === 'transakcja' || field.name === 'typ_nieruchomosci' ? 'md:block hidden' : ''}`}/>
                            )}
                            {field.type === 'select' ? (
                                <select
                                    name={field.name}
                                    value={searchData[field.name]}
                                    onChange={handleChange}
                                    className="w-full p-2 pl-9 border border-gray-300 rounded focus:outline-none"
                                    aria-label={field.placeholder}
                                >
                                    {field.options.map((option, idx) => (
                                        <option key={idx}
                                                value={option === 'Wybierz transakcję' || option === 'Rodzaj nieruchomości' ? '' : option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type={field.type || 'text'}
                                    name={field.name}
                                    value={searchData[field.name]}
                                    onChange={handleChange}
                                    placeholder={field.placeholder}
                                    className="w-full p-2 pl-10 border border-gray-300 rounded focus:outline-none"
                                    aria-label={field.placeholder}
                                    onClick={field.name === 'miejscowosc_dzielnica' ? toggleSuggestions : null}
                                />
                            )}
                            {searchData[field.name] && (
                                <FaTimes
                                    className="absolute right-5 top-3 text-gray-400 cursor-pointer hover:text-gray-600"
                                    onClick={() => handleClear(field.name)}
                                    aria-label="Clear"
                                />
                            )}
                            {field.name === 'miejscowosc_dzielnica' && showSuggestions && (
                                <ul className="absolute w-full bottom-full mb-2 bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto z-10">
                                    {citySuggestions.length > 0 ? (
                                        citySuggestions.map((city, idx) => (
                                            <li
                                                key={idx}
                                                className="p-2 cursor-pointer hover:bg-gray-100"
                                                onMouseDown={() => handleCitySelect(city)}
                                            >
                                                {city}
                                            </li>
                                        ))
                                    ) : (
                                        <li className="p-2 text-gray-500">Brak sugestii</li>
                                    )}
                                </ul>
                            )}
                        </div>
                    ))}
                    <button type="submit"
                            className="bg-[#a07c5c] text-white p-2 rounded flex items-center justify-center hover:bg-[#795b40]">
                        <FaSearch className="mr-2"/>
                        Wyszukaj
                    </button>
                </div>
            </form>

            {loading ? (
                <div className="text-center text-lg">Ładowanie...</div>
            ) : error ? (
                <div className="text-red-600 font-semibold my-4">
                    Błąd: {error}
                </div>
            ) : (
                <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-10">
                        {currentListings.map(item => (
                            <Card key={item.id_ogloszenia} item={item}/>
                        ))}
                    </div>
                    <div className="flex justify-center mt-4">
                        <button onClick={prevPage} disabled={currentPage === 1}
                                className="bg-gray-200 hover:bg-gray-300 text-[#a07c5c] font-bold py-2 px-4 rounded-l">
                            Poprzednia
                        </button>
                        <button
                            onClick={nextPage}
                            disabled={currentListings.length < itemsPerPage}
                            className={`bg-gray-200 hover:bg-gray-300 text-[#a07c5c] font-bold py-2 px-4 rounded-r ${
                                currentListings.length < itemsPerPage ? 'cursor-not-allowed' : ''
                            }`}
                        >
                            Następna
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Listings;
