import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Services = () => {
    const [servicesData, setServicesData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://www.wivfy.space/php/src/api/zarzadzanie.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ action: 'get_management' }),
                });
                const data = await response.json();
                setServicesData(data.management.SERVICES || {});
            } catch (error) {
                setError('Error fetching services data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        AOS.init({ duration: 1000 }); // Initialize AOS
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div id="services" className="relative bg-white">
            <div className="relative bg-white mx-auto max-w-5xl text-center mt-10" data-aos="fade-up">
                <span className="text-black my-3 flex items-center justify-center font-medium uppercase tracking-wider">{servicesData.title}</span>
                <h2 className="block w-full bg-clip-text text-[#a07c5c] text-2xl sm:text-2xl font-bold">
                    {servicesData.subtitle}
                </h2>
                <hr className="border-t-2 border-[#a07c5c] my-4 mx-auto" />
                <p className="mx-auto my-4 w-full max-w-4xl text-center font-medium leading-relaxed tracking-wide text-gray-600">
                    {servicesData.description}
                </p>
            </div>

            <div className="px-4 py-16 mx-auto bg-white sm:max-w-xl lg:max-w-screen-2xl md:max-w-full md:px-24 lg:px-8 lg:py-20">
                <div className="grid gap-12 lg:grid-cols-2">
                    <div className="flex items-center justify-center -mx-4 lg:pl-8" data-aos="fade-up">
                        <div className="flex flex-col items-end px-3">
                            <img
                                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                                src={servicesData.image_1}
                                alt="Service detail 1"
                            />
                            <img
                                className="object-cover w-20 h-20 rounded shadow-md sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                                src={servicesData.image_2}
                                alt="Service detail 2"
                            />
                        </div>
                        <div className="px-3">
                            <img
                                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                                src={servicesData.image_3}
                                alt="Service detail 3"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center" data-aos="fade-up">
                        <div className="max-w-2xl mb-6">
                            <h3 className="max-w-2xl mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl sm:leading-none">
                                {servicesData.main_title}
                            </h3>
                            <p className="text-base text-gray-700 md:text-lg">
                                {servicesData.main_description}
                            </p>
                            <ul className="list-disc ml-6 my-4 text-gray-700 font-medium ">
                                {servicesData.service_1 && <li>{servicesData.service_1}</li>}
                                {servicesData.service_2 && <li>{servicesData.service_2}</li>}
                                {servicesData.service_3 && <li>{servicesData.service_3}</li>}
                                {servicesData.service_4 && <li>{servicesData.service_4}</li>}
                                {servicesData.service_5 && <li>{servicesData.service_5}</li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
