import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaMapMarkerAlt, FaMoneyBillWave, FaExpandArrowsAlt, FaBed } from 'react-icons/fa';

const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
};

const formatRoomsText = (numberOfRooms) => {
    if (numberOfRooms === 1) {
        return 'pokój';
    } else if (numberOfRooms >= 2 && numberOfRooms <= 4) {
        return 'pokoje';
    } else {
        return 'pokoi';
    }
};

const formatCurrency = (amount) => {
    const num = parseFloat(amount);
    if (isNaN(num)) return amount; // Zwróć oryginalny tekst, jeśli nie jest liczbą

    // Formatowanie liczby z użyciem kropek jako separatorów tysięcy
    let formattedNumber = num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${formattedNumber} zł`;
};

const formatNumber = (number) => {
    // Sprawdzenie i konwersja wartości na liczbę
    const num = parseFloat(number);
    if (isNaN(num)) return number; // Zwróć oryginalny tekst, jeśli nie jest liczbą
    return num.toFixed(2).replace('.', ',');
};

const Card = ({ item }) => {
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        if (item.zdjecia && item.zdjecia.length > 0) {
            setImageSrc(item.zdjecia[0]); // Ustawienie pierwszego zdjęcia jako głównego
        }
    }, [item.zdjecia]);

    return (
        <div className="bg-white p-4 shadow-lg rounded-lg relative overflow-hidden transition-transform transform hover:scale-105 duration-300">
            <div className="relative pb-56 mb-4 overflow-hidden rounded-t-lg">
                <picture>
                    <source srcSet={imageSrc.replace('.jpg', '.avif')} type="image/avif" />
                    <source srcSet={imageSrc.replace('.jpg', '.webp')} type="image/webp" />
                    <img
                        src={imageSrc}
                        alt={item.tytul}
                        className="absolute object-cover w-full h-full"
                    />
                </picture>
            </div>
            <div className="flex flex-col space-y-2 mb-4">
                <h2 className="text-xl font-bold mb-2 text-gray-700">{item.miejscowosc_dzielnica}</h2>
                {[
                    {icon: FaMapMarkerAlt, text: `ul. ${item.ulica}`},
                    {icon: FaHome, text: capitalizeFirstLetter(item.typ_nieruchomosci)},
                    {icon: FaMoneyBillWave, text: `${formatCurrency(item.cena)}`},
                    {icon: FaExpandArrowsAlt, text: `${formatNumber(item.powierzchnia)} m²`},
                    {icon: FaBed, text: `${item.liczba_pokoi} ${formatRoomsText(item.liczba_pokoi)}`},
                ].map(({icon: Icon, text, bold}, index) => (
                    <div key={index} className="flex items-center text-sm text-gray-700 space-x-2 mb-2">
                        <Icon className="text-[#a07c5c]"/>
                        <span className={bold ? 'font-bold' : ''}>{text}</span>
                    </div>
                ))}
            </div>
            <Link to={`/listing/${item.id_ogloszenia}`}>
                <button
                    className="bg-[#a07c5c] text-white px-4 py-2 rounded-md absolute bottom-4 right-4 hover:bg-[#795b40] transition-colors duration-300">
                    Sprawdź
                </button>
            </Link>
        </div>
    );
};

export default Card;
